import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import { resourceFilters } from "../components/Listing/config";
import Listing from "../components/Listing/Listing";
import ResourcesResultsArea from "../components/Listing/ResourcesResultsArea";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityLessonsPage } from "../model/lessons";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query ClassroomResourcesPage(
    $_id: String!
    $language: String
    $navLanguage: String
  ) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityResourceListingPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      _rawSideBarText(resolveReferences: { maxDepth: 4 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    content: allSanityResource(
      filter: { language: { eq: $language } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        _id
        _type
        language
        slug {
          current
        }
        title
        subtitle
        image {
          ...SanityImage
        }
        tags {
          _id
          name
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "projects" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const ClassroomResourcesPage = ({
  pageContext,
  data: { menus, campaigns, content, listings },
  location
}: ClassroomResourcesPageProps) => {
  const { _rawSideBarText, title } = listings;
  return (
    <PageLayout
      siteArea={SiteArea.TEACH}
      metadata={{
        title,
        alternates: pageContext.alternates,
        page: listings
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <Listing
        page={listings}
        campaigns={campaigns.nodes}
        title={title}
        config={resourceFilters}
        sideBarText={_rawSideBarText}
        contents={content.nodes}
        renderRows={ResourcesResultsArea}
      />
    </PageLayout>
  );
};

export default ClassroomResourcesPage;

interface ClassroomResourcesPageProps {
  data: {
    menus: GlobalMenus;
    campaigns: { nodes: SanityCampaign[] };
    listings: SanityLessonsPage;
    content: any;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
