import { SanityTopic } from "../model/common";
import { SanityUnitOfWork } from "../model/lessons";

export const getUnitTopics = (unit: SanityUnitOfWork) => {
  const topicsMaybeWithDuplicates = [
    ...unit.topics,
    ...unit.contents.flatMap(lesson => lesson.topics)
  ];
  const unitTopics: SanityTopic[] = [];
  for (const topic of topicsMaybeWithDuplicates) {
    if (!unitTopics.find(t => t._id === topic._id)) {
      unitTopics.push(topic);
    }
  }
  return unitTopics;
};
