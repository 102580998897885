import { Col, Row } from "../../components/Grid";
import React from "react";
import { SanityResourcePage } from "../../model/teach";
import ResourceCard from "../ResourceCard/ResourceCard";
import styles from "./Listing.module.scss";

const ResourcesResultsArea = ({ results }: ResourcesResultsAreaProps) => {
  return (
    <Row>
      {results.map(d => (
        <Col xs={6} sm={6} md={4} className={styles.cardListingCol}>
          <ResourceCard
            key={d._id}
            resource={d}
            className={styles.cardListingCard}
          />
        </Col>
      ))}
    </Row>
  );
};

interface ResourcesResultsAreaProps {
  results: SanityResourcePage[];
}

export default ResourcesResultsArea;
